<template>
  <AdminPageFrame>
    <PageTitle>
      {{ trans('PAGE_TITLE:Partners') }}
    </PageTitle>
    <Loader v-if="partnersDataLoading" class="h-050screen"/>
    <t-card v-else class="w-full">
      <div class="w-full lg:min-w-modal-gallery" v-if="partnersData">
        <DataTable
          :headers="tableHeaders"
          :data="partnersData"
          @edit="editPartner"
          @delete="deletePartner"
        >
          <template #active="{item}">
            {{ item.active ? trans('LABEL:Yes') : trans('LABEL:No') }}
          </template>
          <template #links="{item}">
            <div class="flex flex-col">
              <a v-if="item.www_url" target="_blank" :href="item.www_url">{{ 'WWW' }}</a>
              <a v-if="item.facebook_url" target="_blank" :href="item.facebook_url">{{ 'Facebook' }}</a>
              <a v-if="item.instagram_url" target="_blank" :href="item.instagram_url">{{ 'Instagram' }}</a>
              <a v-if="item.tiktok_url" target="_blank" :href="item.tiktok_url">{{ 'TikTok' }}</a>
              <a v-if="item.youtube_url" target="_blank" :href="item.youtube_url">{{ 'Youtube' }}</a>
              <a v-if="item.linkedin_url" target="_blank" :href="item.linkedin_url">{{ 'Linkedin' }}</a>
            </div>
          </template>
        </DataTable>
        <t-pagination
          variant="posts"
          :total-items="pagination.total"
          :per-page="pagination.perPage"
          :limit="5"
          v-model="currentPage"
          @change="next"
          class="mt-3"
        />
      </div>
    </t-card>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import Button from "@/utils/crud/components/Button";
import DataTable from "@/utils/table/DataTable";
import Loader from "@/views/Loader";
import Modal from "@/utils/modal/Modal";
import PageTitle from "@/layouts/components/pages/PageTitle";
import Pagination from "@/views/content/components/Pagination";
import router from "@/router";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PartnersList",
  components: {
    AdminPageFrame,
    Loader,
    Modal,
    DataTable,
    Button,
    Pagination,
    PageTitle,
  },
  data() {
    return {
      tableHeaders: [
        {
          name: 'LABEL:Actions',
          value: 'actions',
        },
        {
          name: 'ID',
          value: 'id'
        },
        {
          name: 'LABEL:Name',
          value: 'name'
        },
        {
          name: 'LABEL:NIP',
          value: 'nip',
        },
        {
          name: 'LABEL:Email',
          value: 'email',
        },
        {
          name: 'LABEL:CommissionPercentage',
          value: 'commission_percentage',
          width: '10px'
        },
        {
          name: 'LABEL:CommissionSum',
          value: 'commission_sum',
          width: '10px'
        },
        {
          name: 'LABEL:PostalAddress',
          value: 'postal_address',
        },
        {
          name: 'LABEL:IsActive',
          value: 'active',
        },
        {
          name: 'LABEL:Links',
          value: 'links',
        },
        {
          name: 'LABEL:CreatedAt',
          value: 'created_at'
        },
      ],
      currentPage: 1,
      isShowDeletePartnerModal: false,
      partnerToDelete: null,
    }
  },
  watch: {
    partnerDeleted(val) {
      if(val) {
        this.partnerToDelete = null;
        this.isShowDeletePartnerModal = false;
        this.currentPage = 1;
      }
    }
  },
  computed: {
    ...mapGetters('adminPartners', ['partnersData', 'partnersDataLoading', 'pagination', 'partnerDeleting', 'partnerDeleted'])
  },
  methods: {
    ...mapActions('adminPartners', ['fetchPartnersRequest', 'deletePartnerRequest']),
    next(val) {
      this.currentPage = val;
      this.fetchPartnersRequest({
        page: val,
      });
    },
    deletePartner(id) {
	    this.deletePartnerRequest({id: id})
    },
    editPartner(id) {
      router.push({name: 'admin-partners-show', params: {id: id}})
    },
  },
  mounted() {
    this.fetchPartnersRequest({page: this.currentPage});
  }
}
</script>
